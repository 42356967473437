import img55 from "../imagenes/comida.jpg"
import img56 from "../imagenes/comida1.jpg"
import img57 from "../imagenes/comida2.jpg"
import img58 from "../imagenes/comida3.jpg"
import img59 from "../imagenes/comida4.jpg"
import img60 from "../imagenes/comida5.jpg"
import img61 from "../imagenes/comida6.jpg"
import img62 from "../imagenes/comida7.jpg"
import img63 from "../imagenes/comida8.jpg"
import img64 from "../imagenes/comida9.jpg"
import img65 from "../imagenes/comida10.jpg"
import img66 from "../imagenes/comida11.jpg"
import img67 from "../imagenes/comida12.jpg"
import img68 from "../imagenes/comida13.jpg"
import img69 from "../imagenes/comida14.jpg"
import img70 from "../imagenes/comida15.jpg"

export default [
    {id: 55, imgUrl: img55},
    {id: 56, imgUrl: img56},
    {id: 57, imgUrl: img57},
    {id: 58, imgUrl: img58},
    {id: 59, imgUrl: img59},
    {id: 60, imgUrl: img60},
    {id: 61, imgUrl: img61},
    {id: 62, imgUrl: img62},
    {id: 63, imgUrl: img63},
    {id: 64, imgUrl: img64},
    {id: 65, imgUrl: img65},
    {id: 66, imgUrl: img66},
    {id: 67, imgUrl: img67},
    {id: 68, imgUrl: img68},
    {id: 69, imgUrl: img69},
    {id: 70, imgUrl: img70}
];