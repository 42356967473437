import img71 from "../imagenes/hotel.jpg"
import img72 from "../imagenes/hotel1.jpg"
import img73 from "../imagenes/hotel2.jpg"
import img74 from "../imagenes/hotel3.jpg"
import img75 from "../imagenes/hotel4.jpg"
import img76 from "../imagenes/hotel5.jpg"
import img77 from "../imagenes/hotel6.jpg"
import img78 from "../imagenes/hotel7.jpg"

export default [
    {id: 71, imgUrl: img71},
    {id: 72, imgUrl: img72},
    {id: 73, imgUrl: img73},
    {id: 74, imgUrl: img74},
    {id: 75, imgUrl: img75},
    {id: 76, imgUrl: img76},
    {id: 77, imgUrl: img77},
    {id: 78, imgUrl: img78}
];