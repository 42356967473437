import img1 from "../imagenes/brujas3.jpg"
import img2 from "../imagenes/brujas4.jpg"
import img3 from "../imagenes/brujas5.jpg"
import img4 from "../imagenes/brujas6.jpg"
import img5 from "../imagenes/brujas7.jpg"
import img6 from "../imagenes/brujas.jpg"
import img7 from "../imagenes/brujas1.jpg"
import img8 from "../imagenes/brujas2.jpg"
import img9 from "../imagenes/brujas8.jpg"

export default [
    {id: 1, imgUrl: img1},
    {id: 2, imgUrl: img2},
    {id: 2, imgUrl: img3},
    {id: 4, imgUrl: img4},
    {id: 5, imgUrl: img5},
    {id: 6, imgUrl: img6},
    {id: 7, imgUrl: img7},
    {id: 8, imgUrl: img8},
    {id: 9, imgUrl: img9},
];