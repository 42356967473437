import img45 from "../imagenes/semana-santa.jpg"
import img46 from "../imagenes/semana-santa1.jpg"
import img47 from "../imagenes/semana-santa2.jpg"
import img48 from "../imagenes/semana-santa3.jpg"
import img49 from "../imagenes/semana-santa4.jpg"
import img50 from "../imagenes/semana-santa5.jpg"
import img51 from "../imagenes/semana-santa6.jpg"
import img52 from "../imagenes/semana-santa7.jpg"
import img53 from "../imagenes/semana-santa8.jpg"
import img54 from "../imagenes/semana-santa9.jpg"

export default [
    {id: 45, imgUrl: img45},
    {id: 46, imgUrl: img46},
    {id: 47, imgUrl: img47},
    {id: 48, imgUrl: img48},
    {id: 49, imgUrl: img49},
    {id: 50, imgUrl: img50},
    {id: 51, imgUrl: img51},
    {id: 51, imgUrl: img52},
    {id: 52, imgUrl: img53},
    {id: 53, imgUrl: img54}
];