import img11 from "../imagenes/navidad.jpg"
import img12 from "../imagenes/navidad1.jpg"
import img13 from "../imagenes/navidad10.jpg"
import img14 from "../imagenes/navidad2.jpg"
import img15 from "../imagenes/navidad3.jpg"
import img16 from "../imagenes/navidad4.jpg"
import img17 from "../imagenes/navidad5.jpg"
import img18 from "../imagenes/navidad6.jpg"
import img19 from "../imagenes/navidad7.jpg"
import img20 from "../imagenes/navidad8.jpg"
import img21 from "../imagenes/navidad9.jpg"

export default [
    {id: 11, imgUrl: img11},
    {id: 12, imgUrl: img12},
    {id: 12, imgUrl: img13},
    {id: 14, imgUrl: img14},
    {id: 15, imgUrl: img15},
    {id: 16, imgUrl: img16},
    {id: 17, imgUrl: img17},
    {id: 18, imgUrl: img18},
    {id: 19, imgUrl: img19},
    {id: 20, imgUrl: img20},
    {id: 21, imgUrl: img21}
];